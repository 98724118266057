<template>
  <div class="col-12 col-md text-center text-md-right">
    <ul class="bc-list text-dark">
      <li>{{ title }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: ["title"],
};
</script>